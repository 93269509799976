// src/services/api.ts

import axios, { AxiosInstance, AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios';
import { Video } from "../components/VideoList";
import { ContractVideoDetails } from '../components/VideoDetailOverlay';

export const API_BASE_URL = import.meta.env.VITE_APP_API_BASE_URL || 'https://api.v.watch';

export type ApiResponse<T = unknown> = {
    success: boolean;
    data: T;
    msg?: string;
    errcode?: number;
};

interface UploadSessionResponse {
    videoid_new: string;
    access_key_id: string;
    secret_access_key: string;
    session_token: string;
}

export interface Room {
    id: string;
    name: string;
    description: string;
    timestamp: number;
    author: string;
    author_name: string;
    author_avatar: string;
    avatar: string;
    followers_count: number;
    videos_count: number;
    is_private: number;
    address: string;
    domain: string;
}

export interface RoomData {
    joined: Room[];
    created: Room[];
    info: {
        id: number;
        timestamp: number;
        is_closed: number;
        is_private: number;
        author: string;
        name: string;
        description: string;
        domain: string;
        link: string;
        avatar: string;
    };
    total_pages: number;
    room_video: Array<Record<string, {
        videoid: string;
        real_play_id: string;
        convert_dealer_version: number;
        mediaconvert_job_id: string;
        valid: number;
        is_private: number;
        is_audio: number;
        author: string;
        session_creation: string;
        transcode_status: string;
        duration: number;
        view: number;
        comment: number;
        price: number;
        short_code: string | null;
        video_type: string | null;
        video_size: string | null;
        width: number | null;
        height: number | null;
        title: string;
        note: string;
        tags: string;
        challenge_nft_id: number;
        location: string | null;
        winner_videoid: string | null;
        winner_original_videoid: string | null;
        timestamp: number;
    }>>;
    room_follow: string[];
    users_info: Record<string, {
        userid: string;
        valid: number;
        nickname: string;
        signature: string;
        is_vip: number;
        avatar: string;
        gender: string | null;
        create_time: number;
        login_time: number;
        metamask: string;
    }>;
}

export interface RoomListResponse {
    total: number;
    rooms: Room[];
}

class Api {
    private axios: AxiosInstance;
    private baseURL: string;

    constructor() {
        this.baseURL = API_BASE_URL;
        this.axios = axios.create({
            baseURL: this.baseURL,
            headers: {
                'Content-Type': 'application/json'
            }
        });

        this.setupInterceptors();
    }

    private setupInterceptors() {
        this.axios.interceptors.request.use(
            (config) => {
                const userLoginData = localStorage.getItem('userLoginData');
                if (userLoginData) {
                    const { jwt_token } = JSON.parse(userLoginData);
                    if (jwt_token) {
                        config.headers['Authorization'] = `Bearer ${jwt_token}`;
                    }
                }
                return config;
            },
            (error) => Promise.reject(error)
        );

        this.axios.interceptors.response.use(
            (response: AxiosResponse): AxiosResponse => {
                const { data } = response;
                if (data.errcode === 200) {
                    response.data = { success: true, data: data.data };
                } else {
                    response.data = { 
                        success: false, 
                        msg: data.msg || 'An error occurred', 
                        data: data.data || {},
                        errcode: data.errcode
                    };
                }
                return response;
            },
            (error: AxiosError) => {
                return Promise.resolve({
                    data: {
                        success: false,
                        msg: error.message || 'Network error',
                        data: {},
                        errcode: error.response?.status || 500
                    }
                });
            }
        );
    }

    private async request<T = unknown>(
        endpoint: string,
        options: AxiosRequestConfig = {},
        overrideBaseURL?: string
    ): Promise<ApiResponse<T>> {
        try {
            const response = await this.axios({
                url: endpoint,
                ...options,
                baseURL: overrideBaseURL || this.baseURL
            });
            return response.data as ApiResponse<T>;
        } catch (error) {
            if (axios.isCancel(error)) {
                return { 
                    success: false, 
                    msg: 'Request canceled', 
                    data: {} as T,
                    errcode: 499
                };
            }
            console.error('API request failed:', error);
            return { 
                success: false, 
                msg: error instanceof Error ? error.message : 'An error occurred', 
                data: {} as T,
                errcode: 500
            };
        }
    }

    // API methods

    getFeed(userid: string, offset: number, signal?: AbortSignal): Promise<ApiResponse<Video>> {
        return this.request('/v/listoffocus', { 
            method: 'POST', 
            data: { userid, offset },
            signal
        });
    }

    getVideoList(type: string, userid: string, towho: string, offset: number, filter: string, signal?: AbortSignal): Promise<ApiResponse<Video>> {
        return this.request('v/listoftype', { 
            method: 'POST', 
            data: { type, userid, towho, offset, filter },
            signal
        });
    }

    getVideoInfo(videoid: string, userid: string): Promise<ApiResponse<Video>> {
        return this.request('v/getvideoinfo', { method: 'POST', data: { videoid, userid } });
    }

    getComments(videoid: string, userid: string): Promise<ApiResponse<Video>> {
        return this.request('v/getcomments', { method: 'POST', data: { videoid, userid } });
    }

    getRoom(userid: string): Promise<ApiResponse<RoomData>> {
        return this.request('v/room_get', { method: 'POST', data: { userid } });
    }

    setRoom(id: string, name: string, description: string, is_private: number, domain: string): Promise<ApiResponse> {
        return this.request('v/room_modify', { method: 'POST', data: { id, name, description, is_private, domain } });
    }

    getRoomAll(offset: number): Promise<ApiResponse<RoomListResponse>> {
        return this.request('v/room_all', { method: 'POST', data: { offset } });
    }

    getRoomSearch(keyword: string, offset: number): Promise<ApiResponse<RoomListResponse>> {
        return this.request('v/room_search', { method: 'POST', data: { keyword, offset } });
    }

    getRoomDetails(userid: string, id: string, offset: number): Promise<ApiResponse<RoomData>> {
        return this.request('v/room_details', { method: 'POST', data: { userid, id, offset } });
    }

    getUserInfo(userid: string): Promise<ApiResponse> {
        return this.request(`/user/${userid}`, { method: 'GET' });
    }

    setUserInfo(userid: string, nickname: string, signature: string, gender: string, domain: string): Promise<ApiResponse> {
        return this.request(`/user/update_profile`, { method: 'POST', data: { userid, nickname, signature, gender, domain } });
    }

    setUserAvatar(avatar: string): Promise<ApiResponse> {
        return this.request(`/user/update_avatar`, { method: 'POST', data: { avatar } });
    }

    setVideoLollipop(videoid: string, lollipop: number): Promise<ApiResponse> {
        return this.request('/v/set_lollipop', { method: 'POST', data: { videoid, lollipop } });
    }

    getChallengeVideos(nft_id: string): Promise<ApiResponse> {
        return this.request('/v/challenge_videos', { method: 'POST', data: { nft_id } });
    }

    setVideoWinner(videoid: string, winner_videoid: string): Promise<ApiResponse> {
        return this.request('/v/set_winner', { method: 'POST', data: { videoid, winner_videoid } });
    }

    setRoomCreate(name: string, description: string, is_private: number): Promise<ApiResponse> {
        return this.request('v/room_create', { method: 'POST', data: { name, description, is_private } });
    }

    setRoomAvatar(id: string, avatar: string): Promise<ApiResponse> {
        return this.request('v/room_update_avatar', { method: 'POST', data: { id, avatar } });
    }

    setRoomVideoAdd(room_id: string, video_id: string, sign: boolean): Promise<ApiResponse> {
        return this.request('v/room_video_add', { method: 'POST', data: { room_id, video_id, sign } });
    }

    setRoomDestroy(id: string): Promise<ApiResponse> {
        return this.request('v/room_destroy', { method: 'POST', data: { id } });
    }

    setRoomEntryCheck(userid: string, txid: string): Promise<ApiResponse> {
        return this.request('/smartcontract/room_entry/check', { method: 'POST', data: { userid, txid } });
    }

    setRoomUnfollow(userid: string, id: string): Promise<ApiResponse<RoomData>> {
        return this.request('v/room_unfollow', { method: 'POST', data: { userid, id } });
    }

    setReplyToVideo(userid: string, tovideo: string, text: string): Promise<ApiResponse> {
        return this.request('v/msg_video', { method: 'POST', data: { userid, tovideo, text } });
    }

    setReplyToReply(userid: string, videoid: string, text: string, msguid: string, replyguid: string): Promise<ApiResponse> {
        return this.request('v/msg_reply', { method: 'POST', data: { userid, videoid, text, msguid, replyguid } });
    }

    setReplyLikeOrDislike(userid: string, videoid: string, replyguid: string, sign: number): Promise<ApiResponse> {
        return this.request('v/msg_updown', { method: 'POST', data: { userid, videoid, replyguid, sign } });
    }

    setUserFollow(towho: string, sign: boolean): Promise<ApiResponse> {
        return this.request('/user/follow', { method: 'POST', data: { towho, sign } });
    }

    setCreation(
        session_creation: string,
        userid: string,
        videoid: string | null,
        title: string,
        note: string,
        tags: string,
        challenge_nft_id: string,
        is_private: string,
        price: string,
        is_audio: string,
        duration: number,
    ): Promise<ApiResponse> {
        return this.request('v/creation', { method: 'POST', data: {
                session_creation,
                userid,
                videoid,
                title,
                note,
                tags,
                challenge_nft_id,
                is_private,
                price,
                is_audio,
                duration
            }
        });
    }

    getSmartContractVideoDetails(videoid: string, address: string): Promise<ApiResponse<ContractVideoDetails>> {
        return this.request<ContractVideoDetails>(`smartcontract/video/${videoid}`, { 
            method: 'GET',
            headers: {
                'x-user-address': address
            }
        });
    }

    getSmartContractEventsBounty(): Promise<ApiResponse<Video>> {
        return this.request('smartcontract/events/bounty', { method: 'GET' });
    }

    getSessionForUploading(userid: string, session_creation: string): Promise<ApiResponse<UploadSessionResponse>> {
        return this.request<UploadSessionResponse>('upload/session', { method: 'POST', data: {userid, session_creation} });
    }
}

export const api = new Api();