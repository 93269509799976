import { useEffect, useState, useCallback } from 'react';
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom';
import { Card, CardHeader, CardBody, Avatar, Spacer, Button, Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Input, Textarea, Checkbox } from '@nextui-org/react';
import { api, RoomData } from '../services/api';
import VideoList, { Video } from './VideoList';
import { Icon } from "@iconify/react";
import SpinnerV from "./SpinnerV";
import { usePrivy, useWallets } from '@privy-io/react-auth';
import { ethers } from 'ethers';
import { useSnackbar } from 'notistack';
import {polygon} from "wagmi/chains";
import { useTranslation } from 'react-i18next';
import AvatarEditor from 'react-avatar-editor';
import { getAvatarUrl } from '../utils/avatar';

interface LocationState {
    scrollPosition?: number;
}

interface RawVideoData {
    videoid: string;
    real_play_id: string;
    convert_dealer_version: number;
    mediaconvert_job_id: string;
    valid: number;
    is_private: number;
    is_audio: number;
    author: string;
    session_creation: string;
    timestamp: number;
    title?: string;
    lollipop?: number;
    price?: number;
    duration?: number;
    view?: number;
    comment?: number;
    avatar?: string;
    note?: string;
    winner_videoid?: string;
    winner_original_videoid?: string;
}

const RoomDetail = () => {
    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();
    const userLoginData = JSON.parse(localStorage.getItem('userLoginData') || '{}');
    const userId = userLoginData.userid;
    const navigate = useNavigate();
    const location = useLocation();
    const { login, authenticated, user } = usePrivy();
    const { wallets } = useWallets();
    const { enqueueSnackbar } = useSnackbar();

    const [showJoinModal, setShowJoinModal] = useState(false);
    const [showLeaveModal, setShowLeaveModal] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [showDestroyModal, setShowDestroyModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [editForm, setEditForm] = useState({
        name: '',
        description: '',
        is_private: false,
        domain: ''
    });
    const [buttonState, setButtonState] = useState({
        isLoading: true,
        type: '' as 'join' | 'leave' | 'destroy' | ''
    });

    const [roomState, setRoomState] = useState({
        isLoading: true,
        isJoined: false,
        isCreator: false,
        roomData: null as RoomData | null
    });

    const [pagination, setPagination] = useState({
        currentPage: 0,
        totalPages: 1,
        isLoading: false
    });

    const [showAvatarModal, setShowAvatarModal] = useState(false);
    const [avatarImage, setAvatarImage] = useState<File | null>(null);
    const [avatarEditor, setAvatarEditor] = useState<AvatarEditor | null>(null);
    const [avatarZoom, setAvatarZoom] = useState(1);
    const [isUploadingAvatar, setIsUploadingAvatar] = useState(false);

    const [isFormDirty, setIsFormDirty] = useState(false);

    const updateRoomState = useCallback((
        updates: Partial<typeof roomState> | ((prev: typeof roomState) => typeof roomState)
    ) => {
        setRoomState(prev => {
            if (typeof updates === 'function') {
                return updates(prev);
            }
            return { ...prev, ...updates };
        });
    }, []);

    const checkRoomStatus = useCallback(async () => {
        if (id && authenticated) {
            try {
                const response = await api.getRoom(userId);
                if (response.success) {
                    console.log('ID:', id, 'RoomDomain', response.data);
                    // todo
                    const isJoined = response.data.joined.some(room => 
                        String(room.id) === String(id) || room.domain === id
                    );
                    const isCreator = response.data.created.some(room => 
                        String(room.id) === String(id) || room.domain === id
                    );
                    updateRoomState({ isJoined, isCreator });
                    return { isJoined, isCreator };
                } else {
                    if (response.errcode === 1011) {
                        enqueueSnackbar(t('room.errors.roomClosed'), {
                            variant: "warning",
                            autoHideDuration: 5000
                        });
                        setTimeout(() => {
                            navigate('/');
                        }, 2000);
                        return null;
                    }
                    enqueueSnackbar(response.msg || t('room.errors.checkStatusFailed'), {
                        variant: "error",
                        autoHideDuration: 3000
                    });
                    return null;
                }
            } catch (error) {
                console.error('Error checking room status:', error);
                enqueueSnackbar(t('room.errors.checkStatusFailed'), {
                    variant: "error",
                    autoHideDuration: 3000
                });
                return null;
            }
        }
        return null;
    }, [id, authenticated, userId, updateRoomState, enqueueSnackbar, navigate, t]);

    const fetchRoomDetails = async (page: number = 0) => {
        if (id) {
            updateRoomState((prev: typeof roomState) => ({ 
                ...prev,
                isLoading: true,
                roomData: prev.roomData ? {
                    ...prev.roomData,
                    room_video: {} as typeof prev.roomData.room_video
                } : null
            }));
            setPagination(prev => ({ ...prev, isLoading: true }));
            
            try {
                await new Promise(resolve => setTimeout(resolve, 100));
                
                const response = await api.getRoomDetails(userId, id, page);
                if (response.success) {
                    updateRoomState((prev: typeof roomState) => ({
                        ...prev,
                        roomData: {
                            ...response.data,
                            info: prev.roomData?.info || response.data.info,
                            users_info: prev.roomData?.users_info || response.data.users_info
                        } as RoomData,
                        isLoading: false
                    }));
                    setPagination(prev => ({
                        ...prev,
                        currentPage: page,
                        totalPages: response.data.total_pages,
                        isLoading: false
                    }));
                    if (page === 0) {
                        document.title = response.data.info.name;
                    }
                } else {
                    if (response.errcode === 1011) {
                        enqueueSnackbar(t('room.errors.roomClosed'), {
                            variant: "warning",
                            autoHideDuration: 5000
                        });
                        setTimeout(() => {
                            navigate('/');
                        }, 2000);
                    } else {
                        updateRoomState({ isLoading: false });
                        return (
                            <div className="flex flex-col items-center justify-center min-h-[70vh] p-4">
                                <div className="text-center space-y-4">
                                    <Icon icon="solar:ghost-broken" className="w-24 h-24 mx-auto text-default-400" />
                                    <div className="space-y-2">
                                        <h1 className="text-xl font-bold text-default-900">
                                            {t('room.errors.notFound')}
                                        </h1>
                                        <p className="text-small text-default-500">
                                            {response.msg || t('room.errors.loadFailed')}
                                        </p>
                                    </div>
                                    <Button
                                        color="primary"
                                        variant="flat"
                                        onPress={() => navigate('/')}
                                        startContent={<Icon icon="solar:home-2-bold-duotone" />}
                                    >
                                        {t('common.backToHome')}
                                    </Button>
                                </div>
                            </div>
                        );
                    }
                }
            } catch (error) {
                updateRoomState({ isLoading: false });
                setPagination(prev => ({ ...prev, isLoading: false }));
                return (
                    <div className="flex flex-col items-center justify-center min-h-[70vh] p-4">
                        <div className="text-center space-y-4">
                            <Icon icon="solar:ghost-broken" className="w-24 h-24 mx-auto text-default-400" />
                            <div className="space-y-2">
                                <h1 className="text-xl font-bold text-default-900">
                                    {t('common.errors.title')}
                                </h1>
                                <p className="text-small text-default-500">
                                    {t('common.errors.description')}
                                </p>
                            </div>
                            <Button
                                color="primary"
                                variant="flat"
                                onPress={() => navigate('/')}
                                startContent={<Icon icon="solar:home-2-bold-duotone" />}
                            >
                                {t('common.backToHome')}
                            </Button>
                        </div>
                    </div>
                );
            }
        }
    };

    const initializeRoom = async () => {
        setButtonState({ isLoading: true, type: '' });
        await fetchRoomDetails();
        const status = await checkRoomStatus();
        if (status) {
            setButtonState({
                isLoading: false,
                type: status.isCreator ? 'destroy' : (status.isJoined ? 'leave' : 'join')
            });
        }
    };

    useEffect(() => {
        initializeRoom();
    }, [userId, id, checkRoomStatus, updateRoomState, navigate, enqueueSnackbar, t]);

    const joinRoom = useCallback(async () => {
        if (!id) return;

        if (!authenticated) {
            enqueueSnackbar(t('room.errors.connectWalletFirst'), { variant: 'warning' });
            login();
            return;
        }

        // 确保钱包已初始化
        if (!wallets.length) {
            enqueueSnackbar(t('room.errors.walletInitializing'), { variant: 'info' });
            return;
        }

        // 专门获取 Privy 内嵌钱包
        const embeddedWallet = wallets.find(wallet => wallet.walletClientType === 'privy');
        
        if (!embeddedWallet) {
            enqueueSnackbar(t('room.errors.embeddedWalletNotFound'), { variant: 'error' });
            return;
        }

        try {
            // 使用 Privy provider 获取余额
            const provider = await embeddedWallet.getEthereumProvider();
            
            // 确保切换到 Polygon 网络
            try {
                await provider.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: polygon.id }], // Polygon 的 chainId
                });
            } catch (switchError: any) {
                // 如果网络没有添加，则添加 Polygon 网络
                if (switchError.code === 4902) {
                    await provider.request({
                        method: 'wallet_addEthereumChain',
                        params: [{
                            chainId: polygon.id,
                            chainName: polygon.name,
                            nativeCurrency: {
                                name: 'POL',
                                symbol: 'POL',
                                decimals: 18
                            },
                            rpcUrls: polygon.rpcUrls.default.http,
                            blockExplorerUrls: [polygon.blockExplorers.default.url]
                        }]
                    });
                }
            }

            const ethersProvider = new ethers.providers.Web3Provider(provider);
            const signer = ethersProvider.getSigner();
            
            // 获取钱包地址和余额
            const address = await signer.getAddress();
            const balance = await ethersProvider.getBalance(address);
            
            console.log('Using embedded wallet:', address);
            console.log('Balance:', ethers.utils.formatEther(balance), 'MATIC');
            
            // 检查余额是否足够
            const requiredAmount = ethers.utils.parseEther('0.1');
            if (balance.lt(requiredAmount)) {
                throw new Error(`Insufficient balance. You need at least 0.1 MATIC. Current balance: ${ethers.utils.formatEther(balance)} MATIC`);
            }
            
            // 再次确认网络
            const network = await ethersProvider.getNetwork();
            console.log('Current network:', network);
            
            if (network.chainId !== 137) {
                throw new Error('Please switch to Polygon network');
            }
            
            // 估算 gas 费用
            const data = ethers.utils.toUtf8Bytes(`[JR][${userId}][${id}]`);
            const gasEstimate = await signer.estimateGas({
                to: '0x615F0853B746B4cF44344d0A79e5ab58D2376752',
                value: ethers.utils.parseEther('0.1'),
                data: data,
            });
            
            console.log('Estimated Gas:', gasEstimate.toString());
            
            const tx = await signer.sendTransaction({
                to: '0x615F0853B746B4cF44344d0A79e5ab58D2376752',
                value: ethers.utils.parseEther('0.1'),
                data: data,
                gasLimit: gasEstimate.mul(120).div(100), // 添加20%的gas限制作为缓冲
            });

            await tx.wait();

            // 调用 setRoomEntryCheck 接口
            const response = await api.setRoomEntryCheck(userId, tx.hash);
            if (!response.success) {
                throw new Error(response.msg || "Failed to verify room entry");
            }
        } catch (error) {
            console.error('Transaction Error:', error);
            throw error;
        }
    }, [id, authenticated, login, wallets, enqueueSnackbar, t]);

    const leaveRoom = useCallback(async () => {
        if (!user?.id || !id) return;
        const response = await api.setRoomUnfollow(userId, id);
        if (!response.success) {
            throw new Error(response.msg || "Failed to leave room");
        }
    }, [user?.id, id]);

    const destroyRoom = useCallback(async () => {
        if (!id) return;
        const response = await api.setRoomDestroy(id);
        if (!response.success) {
            throw new Error(response.msg || "Failed to destroy room");
        }
    }, [id]);

    const handleJoinLeaveDestroyRoom = useCallback(async () => {
        if (!authenticated) {
            enqueueSnackbar(t('room.errors.connectWalletFirst'), { variant: "info" });
            login();
            return;
        }

        if (roomState.isCreator) {
            // 打开编辑模态框并初始化表单
            setEditForm({
                name: roomState.roomData?.info.name || '',
                description: roomState.roomData?.info.description || '',
                is_private: roomState.roomData?.info.is_private === 1,
                domain: roomState.roomData?.info.domain || ''
            });
            setIsFormDirty(false);  // 重置表单状态
            setShowEditModal(true);
        } else if (roomState.isJoined) {
            setShowLeaveModal(true);
        } else {
            setShowJoinModal(true);
        }
    }, [authenticated, login, enqueueSnackbar, roomState]);

    const confirmJoinRoom = useCallback(async () => {
        if (isProcessing) return;
        
        try {
            setIsProcessing(true);
            await joinRoom();
            setShowJoinModal(false);
            
            // 重新检查房间状态
            const status = await checkRoomStatus();
            if (status) {
                setButtonState({
                    isLoading: false,
                    type: status.isCreator ? 'destroy' : (status.isJoined ? 'leave' : 'join')
                });
            }
            
            enqueueSnackbar(t('room.success.joined'), {
                variant: "success",
                autoHideDuration: 3000
            });
        } catch (error: any) {
            console.error('Join room error:', error);
            setShowJoinModal(false);  // 只在错误时关闭模态框
            
            if (error instanceof Error) {
                if (error.message.includes('insufficient funds')) {
                    enqueueSnackbar(t('room.errors.insufficientBalance'), {
                        variant: "warning",
                        autoHideDuration: 5000
                    });
                } else {
                    enqueueSnackbar(t('common.errors.tryAgainLater'), {
                        variant: "error",
                        autoHideDuration: 3000
                    });
                }
            } else {
                enqueueSnackbar("An unexpected error occurred. Please try again later.", {
                    variant: "error",
                    autoHideDuration: 3000
                });
            }
        } finally {
            setIsProcessing(false);
        }
    }, [joinRoom, checkRoomStatus, enqueueSnackbar, isProcessing, t]);

    const confirmLeaveRoom = useCallback(async () => {
        try {
            setIsProcessing(true);
            await leaveRoom();
            setShowLeaveModal(false);
            // 重新检查房间状态
            const status = await checkRoomStatus();
            if (status) {
                setButtonState({
                    isLoading: false,
                    type: status.isCreator ? 'destroy' : (status.isJoined ? 'leave' : 'join')
                });
            }
            enqueueSnackbar(t('room.success.left'), {
                variant: "success",
                autoHideDuration: 3000
            });
        } catch (error) {
            console.error('Leave room error:', error);
            enqueueSnackbar(t('room.errors.leaveFailed'), {
                variant: "error",
                autoHideDuration: 3000
            });
        } finally {
            setIsProcessing(false);
        }
    }, [leaveRoom, checkRoomStatus, enqueueSnackbar, t]);

    const confirmDestroyRoom = async () => {
        setIsProcessing(true);
        setShowDestroyModal(false);  // Close the modal immediately
        try {
            await destroyRoom();
            enqueueSnackbar(t('room.success.deleted'), { 
                variant: "success",
                autoHideDuration: 3000
            });
            navigate('/');  // Return to home page after destruction
        } catch (error) {
            console.error('Error:', error);
            if (error instanceof Error) {
                if (error.message.includes('insufficient funds')) {
                    enqueueSnackbar("Insufficient balance. Please add funds to your wallet and try again.", {
                        variant: "warning",
                        autoHideDuration: 5000
                    });
                } else {
                    enqueueSnackbar("Oops! Something went wrong. Please try again later.", {
                        variant: "error",
                        autoHideDuration: 3000
                    });
                }
            } else {
                enqueueSnackbar("An unexpected error occurred. Please try again later.", {
                    variant: "error",
                    autoHideDuration: 3000
                });
            }
        } finally {
            setIsProcessing(false);
        }
    };

    const handleFormChange = (field: keyof typeof editForm, value: string | boolean) => {
        const newValue = value;
        
        setEditForm(prev => {
            const newForm = { ...prev, [field]: newValue };
            const isDirty = 
                newForm.name !== roomState.roomData?.info.name ||
                newForm.description !== roomState.roomData?.info.description ||
                newForm.is_private !== (roomState.roomData?.info.is_private === 1) ||
                newForm.domain !== roomState.roomData?.info.domain;
            setIsFormDirty(isDirty);
            return newForm;
        });
    };

    const handleEditRoom = async () => {
        try {
            setIsProcessing(true);
            const response = await api.setRoom(
                id as string,
                editForm.name,
                editForm.description,
                editForm.is_private ? 1 : 0,
                editForm.domain
            );
            
            if (response.success) {
                setIsFormDirty(false);
                enqueueSnackbar(t('room.success.updated'), { variant: 'success' });
                setShowEditModal(false);
                if (editForm.domain !== roomState.roomData?.info.domain) {
                    navigate(`/room/${editForm.domain}`);
                } else {
                    window.location.reload();
                }
            } else {
                if (response.errcode === 10004) {
                    enqueueSnackbar(t('room.errors.domainExists'), { variant: 'error' });
                } else {
                    enqueueSnackbar(response.msg || t('room.errors.updateFailed'), { variant: 'error' });
                }
            }
        } catch (error) {
            console.error('Edit room error:', error);
            if (error instanceof Error) {
                enqueueSnackbar(error.message, { variant: 'error' });
            } else {
                enqueueSnackbar(t('room.errors.updateFailed'), { variant: 'error' });
            }
        } finally {
            setIsProcessing(false);
        }
    };

    const handleVideoClick = (videoId: string) => {
        navigate(location.pathname + location.search, {
            state: { scrollPosition: window.scrollY } as LocationState
        });

        navigate(`/video/${videoId}`);
    };

    useEffect(() => {
        const state = location.state as LocationState;
        if (state && typeof state.scrollPosition === 'number') {
            window.scrollTo(0, state.scrollPosition);
        }
    }, [location]);

    const { roomData } = roomState;

    if (!roomData) {
        return roomState.isLoading ? (
            <div className="flex justify-center items-center h-screen">
                <SpinnerV />
            </div>
        ) : (
            <div className="flex flex-col items-center justify-center min-h-[70vh] p-4">
                <div className="text-center space-y-4">
                    <Icon icon="solar:ghost-broken" className="w-24 h-24 mx-auto text-default-400" />
                    <div className="space-y-2">
                        <h1 className="text-xl font-bold text-default-900">
                            {t('room.errors.notFound')}
                        </h1>
                        <p className="text-small text-default-500">
                            {t('room.errors.roomNotExist')}
                        </p>
                    </div>
                    <Button
                        color="primary"
                        variant="flat"
                        onPress={() => navigate('/')}
                        startContent={<Icon icon="solar:home-2-bold-duotone" />}
                    >
                        {t('common.backToHome')}
                    </Button>
                </div>
            </div>
        );
    }

    const { info, users_info, room_video } = roomData;

    const transformedVideos: Video[] = Object.values(room_video).map(video => {
        const videoData = Object.values(video)[0] as RawVideoData;
        return {
            id: videoData.videoid,
            videoid: videoData.videoid,
            thumbnail: undefined,
            timestamp: videoData.timestamp,
            price: videoData.price || 0,
            duration: videoData.duration || 0,
            view: videoData.view || 0,
            comment: videoData.comment || 0,
            lollipop: videoData.lollipop || 0,
            tags: [],
            is_up: false,
            author: videoData.author || '',
            domain: '', // 如果不需要domain字段，可以设置为空字符串
            avatar: videoData.avatar || '',
            note: videoData.note || '',
            title: videoData.title || '',
            metamask: '',
            playlist: [],
            bywho: (video as any).bywho?.author || '', // 使用类型断言处理可能不存在的属性
            winner_videoid: videoData.winner_videoid || '',
            winner_original_videoid: videoData.winner_original_videoid || '',
            is_followed: false,
            address: '',
            nickname: videoData.author || '',
            is_audio: videoData.is_audio || 0
        };
    });

    const handleAvatarChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            setAvatarImage(e.target.files[0]);
            setShowAvatarModal(true);
        }
    };

    const handleSaveAvatar = async () => {
        if (avatarEditor && id) {
            setIsUploadingAvatar(true);
            try {
                const canvas = avatarEditor.getImageScaledToCanvas();
                const base64Image = canvas.toDataURL('image/jpeg');
                
                const response = await api.setRoomAvatar(id, base64Image);
                if (response.success) {
                    // 更新本地房间数据中的头像
                    updateRoomState(prev => ({
                        ...prev,
                        roomData: prev.roomData ? {
                            ...prev.roomData,
                            info: {
                                ...prev.roomData.info,
                                avatar: base64Image
                            }
                        } : null
                    }));
                    
                    enqueueSnackbar(t('room.success.avatarUpdated'), { variant: 'success' });
                    setShowAvatarModal(false);
                } else {
                    throw new Error(response.msg);
                }
            } catch (error) {
                console.error('Error updating room avatar:', error);
                enqueueSnackbar(t('room.errors.avatarUpdateFailed'), { variant: 'error' });
            } finally {
                setIsUploadingAvatar(false);
            }
        }
    };

    return (
        <div className="p-4">
            <Card className="mb-4">
                <CardHeader className="flex justify-between items-center">
                    <div className="flex items-center gap-3">
                        <Avatar
                            src={getAvatarUrl({ 
                                avatar: info.avatar, 
                                id: info.id.toString(),
                                set: 'set4'
                            })}
                            size="md"
                            className="cursor-default"
                        />
                        <h2 className="sm:text-2xl font-bold">{info.name}</h2>
                    </div>
                    <div className="w-[120px] h-[40px] flex items-center justify-end">
                        {buttonState.isLoading ? (
                            <div className="w-[24px] h-[24px]">
                                <Icon icon="eos-icons:three-dots-loading" width="24" height="24" />
                            </div>
                        ) : (
                            <Button
                                color={buttonState.type === 'leave' ? "secondary" : "primary"}
                                onClick={handleJoinLeaveDestroyRoom}
                                onTouchStart={(e) => {
                                    e.preventDefault();
                                    handleJoinLeaveDestroyRoom();
                                }}
                                disabled={isProcessing}
                                className="w-full h-full"
                            >
                                {isProcessing ? (
                                    <SpinnerV size={20} />
                                ) : (
                                    roomState.isCreator ? t('room.actions.edit') :
                                    buttonState.type === 'leave' ? t('room.actions.leave') : 
                                    t('room.actions.join')
                                )}
                            </Button>
                        )}
                    </div>
                </CardHeader>
                <CardBody>
                    <p>{info.description}</p>
                    <Spacer y={2} />
                    <div className="flex flex-wrap gap-2">
                        {Object.values(users_info).map((user) => (
                            <Link
                                key={user.userid}
                                to={`/user/${user.userid}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Avatar
                                    src={getAvatarUrl({ 
                                        avatar: user.avatar, 
                                        id: user.userid.toString() 
                                    })}
                                    size="sm"
                                />
                            </Link>
                        ))}
                    </div>
                </CardBody>
            </Card>

            <VideoList
                videos={transformedVideos}
                onVideoClick={handleVideoClick}
            />

            {/* 加载状态 */}
            {pagination.isLoading && (
                <div className="flex justify-center items-center my-4">
                    <SpinnerV />
                </div>
            )}

            {/* 分页按钮，只在非加载状态显示 */}
            {!pagination.isLoading && (
                <div className="flex justify-center gap-2 mt-4">
                    {Array.from({ length: pagination.totalPages }, (_, i) => (
                        <Button
                            key={i}
                            size="sm"
                            variant={pagination.currentPage === i ? "solid" : "bordered"}
                            color={pagination.currentPage === i ? "primary" : "default"}
                            onClick={() => fetchRoomDetails(i)}
                            onTouchStart={(e) => {
                                e.preventDefault();
                                fetchRoomDetails(i);
                            }}
                            disabled={pagination.isLoading}
                        >
                            {i + 1}
                        </Button>
                    ))}
                </div>
            )}

            <Modal isOpen={showJoinModal} onClose={() => !isProcessing && setShowJoinModal(false)}>
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">{t('room.modal.join.title')}</ModalHeader>
                            <ModalBody>
                                <p>{t('room.modal.join.message')}</p>
                            </ModalBody>
                            <ModalFooter>
                                {!isProcessing && (
                                    <Button 
                                        color="danger" 
                                        variant="light" 
                                        onPress={onClose}
                                    >
                                        {t('common.cancel')}
                                    </Button>
                                )}
                                <Button 
                                    color="primary" 
                                    onPress={confirmJoinRoom}
                                    disabled={isProcessing}
                                >
                                    {isProcessing ? (
                                        <div className="flex items-center gap-2">
                                            <SpinnerV size={20} />
                                            <span>{t('room.modal.join.processing')}</span>
                                        </div>
                                    ) : t('room.actions.join')}
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>

            <Modal isOpen={showLeaveModal} onClose={() => setShowLeaveModal(false)}>
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">{t('room.modal.leave.title')}</ModalHeader>
                            <ModalBody>
                                <p>{t('room.modal.leave.message')}</p>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary" variant="light" onPress={onClose}>
                                    {t('common.cancel')}
                                </Button>
                                <Button color="danger" onPress={confirmLeaveRoom}>
                                    {t('room.actions.leave')}
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>

            <Modal isOpen={showDestroyModal} onClose={() => setShowDestroyModal(false)}>
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">{t('room.modal.delete.title')}</ModalHeader>
                            <ModalBody>
                                <p>{t('room.modal.delete.message')}</p>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary" variant="light" onPress={onClose}>
                                    {t('common.cancel')}
                                </Button>
                                <Button color="danger" onPress={confirmDestroyRoom}>
                                    {t('room.actions.delete')}
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>

            <Modal isOpen={showEditModal} onClose={() => !isProcessing && setShowEditModal(false)}>
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">
                                {t('room.modal.edit.title')}
                            </ModalHeader>
                            <ModalBody>
                                <div className="flex flex-col gap-4">
                                    <div className="flex justify-center mb-2">
                                        <div className="relative group">
                                            <Avatar
                                                src={getAvatarUrl({ 
                                                    avatar: info.avatar, 
                                                    id: info.id.toString(),
                                                    set: 'set4'
                                                })}
                                                size="lg"
                                                className="w-24 h-24 cursor-pointer"
                                            />
                                            <label className="absolute inset-0 flex items-center justify-center bg-black/40 rounded-full opacity-0 group-hover:opacity-100 transition-opacity cursor-pointer">
                                                <input
                                                    type="file"
                                                    className="hidden"
                                                    accept="image/*"
                                                    onChange={handleAvatarChange}
                                                    aria-label={t('room.changeAvatar')}
                                                />
                                                <Icon 
                                                    icon="material-symbols:edit" 
                                                    className="text-white" 
                                                    width={24} 
                                                />
                                            </label>
                                        </div>
                                    </div>
                                    <Input
                                        label={t('room.roomName')}
                                        placeholder={t('room.roomNamePlaceholder')}
                                        value={editForm.name}
                                        onChange={(e) => handleFormChange('name', e.target.value)}
                                    />
                                    <Input
                                        label={t('room.domain')}
                                        placeholder={t('room.domainPlaceholder')}
                                        value={editForm.domain}
                                        onChange={(e) => handleFormChange('domain', e.target.value)}
                                        startContent={
                                            <div className="pointer-events-none flex items-center">
                                                <span className="text-default-400 text-small">https://v.watch/room/</span>
                                            </div>
                                        }
                                    />
                                    <Textarea
                                        label={t('room.description')}
                                        placeholder={t('room.descriptionPlaceholder')}
                                        value={editForm.description}
                                        onChange={(e) => handleFormChange('description', e.target.value)}
                                    />
                                    <Checkbox
                                        isSelected={editForm.is_private}
                                        onValueChange={(isSelected) => handleFormChange('is_private', isSelected)}
                                    >
                                        {t('room.makePrivate')}
                                    </Checkbox>
                                </div>
                            </ModalBody>
                            <ModalFooter className="flex justify-between">
                                <Button 
                                    color="danger" 
                                    variant="light"
                                    onPress={() => setShowDestroyModal(true)}
                                    isDisabled={isProcessing}
                                >
                                    {t('room.actions.delete')}
                                </Button>
                                <div className="flex gap-2">
                                    <Button 
                                        color="default" 
                                        variant="light" 
                                        onPress={onClose}
                                        isDisabled={isProcessing}
                                    >
                                        {t('common.cancel')}
                                    </Button>
                                    <Button 
                                        color="primary"
                                        onPress={handleEditRoom}
                                        isDisabled={isProcessing || !isFormDirty}
                                    >
                                        {isProcessing ? (
                                            <SpinnerV size={20} />
                                        ) : t('common.save')}
                                    </Button>
                                </div>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>

            <Modal 
                isOpen={showAvatarModal} 
                onClose={() => setShowAvatarModal(false)}
                size="sm"
            >
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader>
                                <h3 className="text-lg font-semibold">
                                    {t('room.editAvatar')}
                                </h3>
                            </ModalHeader>
                            <ModalBody>
                                <div className="flex flex-col items-center gap-4">
                                    {avatarImage && (
                                        <>
                                            <div className="border rounded-lg overflow-hidden">
                                                <AvatarEditor
                                                    ref={(editor) => setAvatarEditor(editor)}
                                                    image={avatarImage}
                                                    width={200}
                                                    height={200}
                                                    border={0}
                                                    borderRadius={100}
                                                    color={[255, 255, 255, 0.6]}
                                                    scale={avatarZoom}
                                                    rotate={0}
                                                />
                                            </div>
                                            <div className="w-full flex flex-col gap-2">
                                                <span className="text-sm text-gray-600">
                                                    {t('room.zoom')}
                                                </span>
                                                <input
                                                    type="range"
                                                    min="1"
                                                    max="2"
                                                    step="0.01"
                                                    value={avatarZoom}
                                                    onChange={(e) => setAvatarZoom(parseFloat(e.target.value))}
                                                    className="w-full"
                                                />
                                            </div>
                                        </>
                                    )}
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <Button 
                                    color="danger" 
                                    variant="light" 
                                    onPress={onClose}
                                    isDisabled={isUploadingAvatar}
                                >
                                    {t('common.cancel')}
                                </Button>
                                <Button 
                                    color="primary"
                                    onPress={handleSaveAvatar}
                                    isLoading={isUploadingAvatar}
                                    isDisabled={!avatarImage || isUploadingAvatar}
                                >
                                    {isUploadingAvatar ? t('common.saving') : t('common.save')}
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </div>
    );
};

export default RoomDetail;
