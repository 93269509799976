import { useTranslation } from 'react-i18next';
import { Tooltip } from "@nextui-org/react";
import { Icon } from "@iconify/react";
import { cn } from "../utils/cn";
import { useMediaQuery } from "usehooks-ts";

export default function LanguageSwitcher() {
  const { i18n } = useTranslation();
  const isCompact = useMediaQuery("(max-width: 768px)");

  const handleLanguageToggle = () => {
    i18n.changeLanguage(i18n.language === 'zh' ? 'en' : 'zh');
    localStorage.setItem('i18nextLng', i18n.language);
  };

  return (
    <Tooltip isDisabled={!isCompact} placement="right">
      <button
        onClick={handleLanguageToggle}
        className={cn(
          "w-full flex items-center justify-start px-3 py-2 text-default-500 hover:text-gray-700 data-[hover=true]:bg-default-400/10",
          {
            "justify-center": isCompact,
          },
        )}
        aria-label={isCompact ? (i18n.language === 'zh' ? 'Switch to English' : '切换到中文') : undefined}
      >
        <Icon
          className="text-default-600"
          icon={i18n.language === 'zh' ? 'twemoji:flag-china' : 'twemoji:flag-united-states'}
          width={26}
          aria-hidden="true"
        />
        {!isCompact}
      </button>
    </Tooltip>
  );
} 