import React from "react";
import {Image} from "@nextui-org/react";
import {Icon} from "@iconify/react";
import {Video} from "./VideoList";

interface VideoListItemProps extends Video {
    isLoading?: boolean;
    onVideoClick: (videoId: string) => void;
}

const VideoListItem: React.FC<VideoListItemProps> = ({
                                                         videoid,
                                                         title,
                                                         note,
                                                         price,
                                                         duration,
                                                         view,
                                                         comment,
                                                         isLoading,
                                                         onVideoClick,
                                                         lollipop,
                                                     }) => {
    const formatDuration = (seconds: number) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
    };

    const formatViews = (count: number) => {
        if (count >= 1000000) {
            return `${(count / 1000000).toFixed(1)}M`;
        } else if (count >= 1000) {
            return `${(count / 1000).toFixed(1)}K`;
        }
        return count.toString();
    };

    return (
        <div className="w-full overflow-hidden">
            {isLoading ? (
                <div className="h-[200px] bg-default-300 animate-pulse rounded-large" />
            ) : (
                <div className="flex flex-col h-full">
                    <div
                        className="relative group rounded-large overflow-hidden cursor-pointer"
                        onClick={() => onVideoClick(videoid)}
                    >
                        <Image
                            isZoomed
                            width="100%"
                            alt={title}
                            className="w-full object-cover aspect-square z-0 transition-transform duration-300 group-hover:scale-105 select-none pointer-events-none"
                            src={`https://file.v.watch/cover/${videoid}.jpg`}
                            draggable={false}
                            style={{ WebkitTouchCallout: 'none' }}
                        />
                        <div className="absolute top-1.5 right-1.5 bg-background/60 backdrop-blur-md backdrop-saturate-150 dark:bg-default-100/50 px-1.5 py-0.5 rounded-lg text-[10px] font-medium z-10 pointer-events-none select-none flex flex-col gap-0.5">
                            <div className="flex items-center justify-end w-full">
                                <span className="text-default-500">{formatViews(view)}</span>
                                <Icon icon="mdi:eye-outline" className="text-default-500 ml-0.5" width={12}/>
                            </div>
                            {comment > 0 && (
                                <div className="flex items-center justify-end w-full">
                                    <span className="text-default-500">{formatViews(comment)}</span>
                                    <Icon icon="mdi:comment-outline" className="text-default-500 ml-0.5" width={12}/>
                                </div>
                            )}
                            {lollipop > 0 && (
                                <div className="flex items-center justify-end w-full">
                                    <span className="text-default-500">{formatViews(lollipop)}</span>
                                    <Icon icon="mdi:gift-outline" className="text-rose-300 ml-0.5" width={12}/>
                                </div>
                            )}
                        </div>
                        <div className="absolute bottom-1.5 right-1.5 bg-background/60 backdrop-blur-md backdrop-saturate-150 dark:bg-default-100/50 px-1.5 py-0.5 rounded-lg text-[10px] font-medium z-10 pointer-events-none select-none">
                            {formatDuration(duration)}
                        </div>
                    </div>
                    <div className="flex flex-col p-2 flex-grow">
                        <div className="flex justify-between items-center w-full mb-1">
                            <h4 className="font-bold text-base truncate">{title}</h4>
                            <div className="flex items-center gap-2 ml-2 flex-shrink-0">
                                {price > 0 && (
                                    <div className="flex items-center">
                                        <Icon icon="cryptocurrency-color:matic" className="text-yellow-400 mr-1" width={16}/>
                                        <span className="text-small font-medium">{price}</span>
                                    </div>
                                )}
                            </div>
                        </div>
                        <p className="text-small text-default-500 line-clamp-2">{note}</p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default VideoListItem;