import React, { useState, useEffect, useRef } from 'react';
import { Tabs, Tab, Card, CardBody, Button } from "@nextui-org/react";
import { Icon } from '@iconify/react';
import { motion, AnimatePresence } from 'framer-motion';
import { api } from "../services/api";
import SpinnerV from "./SpinnerV";
import { useTranslation } from 'react-i18next';

interface IcebreakerItem {
    description: string;
    videoId: string;
    bonus: string;
    bonusAdded: string;
}

interface IcebreakersData {
    boosted: IcebreakerItem[];
    inProgress: IcebreakerItem[];
    fulfilled: IcebreakerItem[];
}

const IcebreakersContent: React.FC = () => {
    const { t } = useTranslation();
    const [data, setData] = useState<IcebreakersData>({
        boosted: [],
        inProgress: [],
        fulfilled: []
    });
    const [isLoading, setIsLoading] = useState(true);
    const [hoveredCard, setHoveredCard] = useState<number | null>(null);
    const dataFetchedRef = useRef(false);

    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;

        const fetchData = async () => {
            setIsLoading(true);
            try {
                const response = await api.getSmartContractEventsBounty();
                if (response.success) {
                    setData(response.data as unknown as IcebreakersData);
                } else {
                    console.error("Failed to fetch icebreakers data");
                }
            } catch (error) {
                console.error("An error occurred while fetching icebreakers data:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();

        return () => {
            dataFetchedRef.current = false;
        };
    }, []);

    const formatNumber = (value: string): string => {
        try {
            const bigIntValue = BigInt(value);
            const scaledValue = Number(bigIntValue) / 1e18;
            return scaledValue.toLocaleString(undefined, { maximumFractionDigits: 2 });
        } catch (error) {
            console.error("Error formatting number:", error);
            return 'N/A';
        }
    };

    const calculateHeat = (bonus: string, bonusAdded: string): string => {
        try {
            const bonusBigInt = BigInt(bonus);
            const addedBigInt = BigInt(bonusAdded);
            if (bonusBigInt === BigInt(0)) return '0%';
            const heatRatio = Number((addedBigInt * BigInt(10000)) / bonusBigInt) / 100;
            return `${heatRatio.toFixed(2)}%`;
        } catch (error) {
            console.error("Error calculating heat:", error);
            return 'N/A';
        }
    };

    const renderList = (items: IcebreakerItem[], tabKey: string) => (
        <div className="space-y-4">
            {items.map((item, index) => (
                <motion.div
                    key={index}
                    className="relative"
                    onHoverStart={() => setHoveredCard(index)}
                    onHoverEnd={() => setHoveredCard(null)}
                >
                    <Card className={`w-full ${tabKey === 'fulfilled' ? 'bg-gradient-to-r from-gray-500 to-gray-700' : 'bg-gradient-to-r from-purple-600 to-purple-400'} text-white rounded-lg shadow-md relative overflow-hidden`}>
                        <CardBody>
                            <h3 className="text-lg font-semibold mb-2">{item.description}</h3>
                            <p className="text-sm mb-2">
                                Bonus: {formatNumber(item.bonus)} POL
                                {tabKey !== 'fulfilled' && (
                                    <span className="ml-2 text-xs bg-purple-700 px-2 py-1 rounded-full">
                                        🔺 {calculateHeat(item.bonus, item.bonusAdded)}
                                    </span>
                                )}
                            </p>
                            <Button
                                isIconOnly
                                color="secondary"
                                className={`absolute bottom-2 right-2 rounded-full ${tabKey === 'fulfilled' ? 'hover:bg-primary-600' : 'hover:bg-warning-200'} group z-10 cursor-pointer touch-action-none`}
                                size="sm"
                                onPress={() => window.open(`/video/${item.videoId}`, '_blank')}
                            >
                                <Icon
                                    icon={tabKey === 'fulfilled' ? "fluent:video-clip-20-filled" : "mingcute:sword-line"}
                                    width="20"
                                    height="20"
                                    className="text-white group-hover:text-red-500 transition-colors duration-300"
                                />
                            </Button>
                        </CardBody>
                    </Card>
                    <AnimatePresence>
                        {hoveredCard === index && (
                            <motion.div
                                className="absolute inset-0 pointer-events-none"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: 0.3 }}
                            >
                                <motion.div
                                    className="absolute inset-0 rounded-lg"
                                    style={{
                                        border: '3px solid rgba(255, 255, 255, 0.5)',
                                        maskImage: 'linear-gradient(to right, transparent, white 50%, transparent)',
                                        maskSize: '300% 100%',
                                        maskPosition: 'right',
                                    }}
                                    initial={{ maskPosition: 'right' }}
                                    animate={{ maskPosition: 'left' }}
                                    transition={{ duration: 1.5, ease: 'easeInOut' }}
                                />
                                <motion.div
                                    className="absolute inset-0"
                                    style={{
                                        background: 'linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent)',
                                        width: '30%',
                                    }}
                                    initial={{ left: '-30%' }}
                                    animate={{ left: '100%' }}
                                    transition={{
                                        duration: 1.2,
                                        ease: [0.25, 0.1, 0.25, 1],
                                    }}
                                />
                            </motion.div>
                        )}
                    </AnimatePresence>
                </motion.div>
            ))}
        </div>
    );

    return (
        <>
            <style>{`
                .scrollbar-hide::-webkit-scrollbar {
                    display: none;
                }
                .scrollbar-hide {
                    -ms-overflow-style: none;
                    scrollbar-width: none;
                }
            `}</style>
            <div className="flex flex-col h-full relative">
                <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 mb-8 p-4">
                    <div className="flex-1">
                        <div className="flex items-center gap-2">
                            <h1 className="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-primary to-secondary">
                                {t('icebreakers.title', 'Discover and challenges')}
                            </h1>
                            <Button
                                variant="light"
                                size="sm"
                                onPress={() => window.open('https://icebreakers.v.watch', '_blank')}
                                className="cursor-pointer touch-action-none"
                                startContent={
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 72 72">
                                        <path fill="#ffd3b6" d="M68 28.1H33c1.2-1 1.6-1.9 2.9-3.2c6.9-7.2 5-10.7 3.6-12.3s-3.9-1.2-5.5.4c-7.8 9-20.5 13.2-21.8 14.4c-1.3.8-2.3 2.3-3 3.8H2.9c-1.6 0-2.9 1.3-2.9 2.9v18.6c0 1.6 1.3 2.9 2.9 2.9H11c2.3 2.8 5.9 4.6 9.8 4.6h26.5c2.2 0 4.1-1.8 4.1-4.1c0-1-.4-2-1-2.7c0 0 0-.1-.1-.2q-1.2-1.2 0-2.4c.7-.7 1.1-1.7 1.1-2.8s-.4-2-1-2.7c0 0 0-.1-.1-.2c-.8-.8-.8-1.6-.1-2.4c.7-.7 1.2-1.8 1.2-2.9s-.4-2-1-2.7c0 0 0-.1-.1-.1q-.45-.45-.6-.9H68c2.2 0 4.1-1.8 4.1-4.1c-.1-2.1-1.9-3.9-4.1-3.9"/>
                                        <path fill="#f2c7aa" d="M8.1 47.6V35.2c0-1 .4-2.5 1-3.9H7.7c-1.2 0-2.2 1-2.2 2.2v20c0 1.2 1 2.2 2.2 2.2H11c-1.8-2.2-2.9-5-2.9-8.1"/>
                                    </svg>
                                }
                            >
                                <span className="text-blue-500">Icebreakers Portal</span>
                            </Button>
                        </div>
                        <p className="text-default-500 mt-1">
                            {t('icebreakers.subtitle', 'Explore and participate in exciting challenges')}
                        </p>
                    </div>
                </div>
                <div className="flex-none bg-white z-20">
                    <Tabs
                        aria-label={t('icebreakers.tabs.ariaLabel')}
                        color="secondary"
                        variant="underlined"
                        classNames={{
                            tabList: "gap-6 w-full relative rounded-none p-0 border-b border-gray-300",
                            cursor: "w-full bg-purple-500",
                            tab: "max-w-fit px-0 h-12",
                            tabContent: "group-data-[selected=true]:text-purple-600",
                        }}
                    >
                        <Tab key="boosted" title={
                            <div className="flex items-center justify-center space-x-2 text-gray-700 w-full h-full">
                                <Icon
                                    className="text-gray-600"
                                    icon="flowbite:rocket-solid"
                                    width={24}
                                />
                                <span>{t('icebreakers.tabs.boosted')}</span>
                            </div>
                        }>
                            <div className="mt-4 overflow-y-auto scrollbar-hide" style={{ maxHeight: 'calc(100vh - 130px)' }}>
                                {renderList(data.boosted, 'boosted')}
                            </div>
                        </Tab>
                        <Tab key="inProgress" title={
                            <div className="flex items-center justify-center space-x-2 text-gray-700 w-full h-full">
                                <Icon
                                    className="text-gray-600"
                                    icon="ri:progress-6-line"
                                    width={24}
                                />
                                <span>{t('icebreakers.tabs.inProgress')}</span>
                            </div>
                        }>
                            <div className="mt-4 overflow-y-auto scrollbar-hide" style={{ maxHeight: 'calc(100vh - 130px)' }}>
                                {renderList(data.inProgress, 'inProgress')}
                            </div>
                        </Tab>
                        <Tab key="fulfilled" title={
                            <div className="flex items-center justify-center space-x-2 text-gray-700 w-full h-full">
                                <Icon
                                    className="text-gray-600"
                                    icon="icons8:finish-flag"
                                    width={24}
                                />
                                <span>{t('icebreakers.tabs.fulfilled')}</span>
                            </div>
                        }>
                            <div className="mt-4 overflow-y-auto scrollbar-hide" style={{ maxHeight: 'calc(100vh - 130px)' }}>
                                {renderList(data.fulfilled, 'fulfilled')}
                            </div>
                        </Tab>
                    </Tabs>
                </div>
                {isLoading && (
                    <div className="absolute inset-0 z-30 flex items-center justify-center bg-white bg-opacity-70">
                        <SpinnerV />
                    </div>
                )}
            </div>
        </>
    );
};

export default IcebreakersContent;