import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Avatar, Card, CardBody, CardHeader, Divider, Button, Tooltip, CardFooter } from "@nextui-org/react";
import { Icon } from "@iconify/react";
import { api } from '../services/api';
import VideoList, { Video } from './VideoList';
import { motion } from 'framer-motion';
import { useSnackbar } from 'notistack';
import SpinnerV from "./SpinnerV";
import { useTranslation } from 'react-i18next';
import { getAvatarUrl } from '../utils/avatar';

interface UserInfo {
  userid: string;
  nickname: string;
  avatar: string;
  signature: string;
  gender: string;
  domain: string;
  fansCount: number;
  isFollowed: boolean;
}

interface Room {
  id: number;
  name: string;
  avatar: string;
}

interface UserResponse {
  userInfo: UserInfo;
  videos: Video[];
  rooms: Room[];
}

const CACHE_EXPIRATION = 5 * 60 * 1000; // 5 minutes

const UserProfile: React.FC = () => {
  const { t } = useTranslation();
  const { userid } = useParams<{ userid: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const [userData, setUserData] = useState<UserResponse | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isFollowed, setIsFollowed] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [isProcessing, setIsProcessing] = useState(false);

  const [currentUserId] = useState(() => {
    const userLoginData = JSON.parse(localStorage.getItem('userLoginData') || '{}');
    return userLoginData.userid;
  });

  const getCachedData = useCallback(() => {
    const cachedData = localStorage.getItem(`userProfile_${userid}`);
    if (cachedData) {
      const { data, timestamp } = JSON.parse(cachedData);
      if (Date.now() - timestamp < CACHE_EXPIRATION) {
        return data;
      }
    }
    return null;
  }, [userid]);

  const setCachedData = useCallback((data: UserResponse) => {
    localStorage.setItem(`userProfile_${userid}`, JSON.stringify({
      data,
      timestamp: Date.now()
    }));
  }, [userid]);

  const fetchUserData = useCallback(async (useCache = true) => {
    const cachedData = useCache ? getCachedData() : null;
    if (cachedData) {
      setUserData(cachedData);
      setIsFollowed(cachedData.userInfo.isFollowed);
    }

    setIsLoading(true);
    try {
      const response = await api.getUserInfo(userid || '');
      if (response.success && response.data) {
        const typedData = response.data as UserResponse;
        setUserData(typedData);
        setIsFollowed(typedData.userInfo.isFollowed);
        setCachedData(typedData);
      } else {
        console.error("Failed to fetch user info:", response.msg);
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    } finally {
      setIsLoading(false);
    }
  }, [userid, getCachedData, setCachedData]);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  useEffect(() => {
    const state = location.state as { scrollPosition?: number };
    if (state && typeof state.scrollPosition === 'number') {
      window.scrollTo(0, state.scrollPosition);
    }
  }, [location]);

  useEffect(() => {
    if (userData?.userInfo?.nickname) {
      document.title = `${userData.userInfo.nickname} - V Watch Video`;
    }
    return () => {
      document.title = 'V Watch Video';
    };
  }, [userData]);

  const handleVideoClick = useCallback((videoId: string) => {
    navigate(`/video/${videoId}`, {
      state: { scrollPosition: window.scrollY }
    });
  }, [navigate]);

  const handleFollowToggle = useCallback(async () => {
    if (!userData?.userInfo?.userid) return;
    setIsProcessing(true);

    try {
      const response = await api.setUserFollow(userData.userInfo.userid, !isFollowed);
      if (response.success) {
        setIsFollowed(!isFollowed);
        setUserData(prevData => {
          if (prevData) {
            const newData = {
              ...prevData,
              userInfo: {
                ...prevData.userInfo,
                isFollowed: !isFollowed,
                fansCount: isFollowed ? prevData.userInfo.fansCount - 1 : prevData.userInfo.fansCount + 1
              }
            };
            setCachedData(newData);
            return newData;
          }
          return prevData;
        });
        enqueueSnackbar(
          isFollowed ? t('userProfile.notifications.unfollowSuccess') : t('userProfile.notifications.followSuccess'),
          { variant: "success", autoHideDuration: 3000 }
        );
      } else {
        throw new Error(response.msg || t('userProfile.errors.followToggleFailed'));
      }
    } catch (error) {
      console.error('Error toggling follow:', error);
      enqueueSnackbar(t('userProfile.errors.generic'), {
        variant: "error",
        autoHideDuration: 3000
      });
    } finally {
      setIsProcessing(false);
    }
  }, [userData, isFollowed, enqueueSnackbar, setCachedData, t]);

  const handleRefresh = useCallback(() => {
    fetchUserData(false);
  }, [fetchUserData]);

  const isSelfOrSameDomain = useCallback((userInfo: UserInfo) => {
    return currentUserId === userInfo.userid || 
           (currentUserId && userInfo.domain && currentUserId.startsWith(userInfo.domain));
  }, [currentUserId]);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <SpinnerV size={40} />
      </div>
    );
  }

  if (!userData) {
    return (
      <motion.div 
        initial={{ opacity: 0, y: 0 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="container mx-auto"
      >
        <Card className="w-full max-w-3xl mx-auto bg-gradient-to-br from-purple-100 to-indigo-100">
          <CardBody className="py-12 flex flex-col items-center justify-center">
            <Icon
              icon="mdi:account-search"
              className="w-16 h-16 text-gray-400 mb-4"
            />
            <h2 className="text-2xl font-bold mb-2">{t('userProfile.notFound.title')}</h2>
            <p className="text-gray-500">{t('userProfile.notFound.description')}</p>
            <Button
              className="mt-6"
              color="primary"
              variant="light"
              onPress={() => navigate('/')}
            >
              {t('videoDetail.actions.goBack')}
            </Button>
          </CardBody>
        </Card>
      </motion.div>
    );
  }

  const { userInfo, videos } = userData;

  return (
    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="container mx-auto px-3 py-3"
    >
      <Card className="w-full max-w-3xl mx-auto bg-gradient-to-br from-purple-100 to-indigo-100">
        <CardHeader className="flex flex-col p-6 relative">
          <Button
            size="sm"
            variant="light"
            isIconOnly
            onPress={handleRefresh}
            disabled={isLoading}
            className="absolute top-2 right-2 cursor-pointer touch-action-none"
            aria-label={t('userProfile.actions.refresh')}
          >
            <Icon icon="mdi:refresh" className={isLoading ? "animate-spin" : ""} />
          </Button>
          <div className="flex flex-col sm:flex-row items-center w-full">
            <Avatar
              src={getAvatarUrl({
                avatar: userInfo.avatar,
                id: userInfo.userid,
                set: 'set4'
              })}
              className="w-20 h-20 text-large shrink-0"
              isBordered
              color="secondary"
            />
            <div className="ml-0 sm:ml-6 mt-4 sm:mt-0 text-center sm:text-left flex-grow">
              <h1 className="text-2xl font-bold">{userInfo.nickname}</h1>
              <p className="text-gray-500 mt-1 break-words">{userInfo.signature}</p>
              <div className="flex items-center justify-center sm:justify-start mt-2">
                <Tooltip content={t('userProfile.tooltips.followers')}>
                  <div className="flex items-center mr-4">
                    <Icon icon="mdi:account-group" className="mr-1" />
                    <span>{userInfo.fansCount}</span>
                  </div>
                </Tooltip>
                {currentUserId && !isSelfOrSameDomain(userInfo) && (
                  <Button 
                    size="sm" 
                    color={isFollowed ? "secondary" : "primary"}
                    variant={isFollowed ? "bordered" : "solid"}
                    startContent={isProcessing ? <SpinnerV size={20} /> : <Icon icon={isFollowed ? "mdi:account-check" : "mdi:account-plus"} />}
                    onPress={handleFollowToggle}
                    disabled={isProcessing}
                    className="cursor-pointer touch-action-none"
                  >
                    {isProcessing ? "" : (isFollowed ? t('userProfile.actions.unfollow') : t('userProfile.actions.follow'))}
                  </Button>
                )}
              </div>
            </div>
          </div>
          {userData.rooms && userData.rooms.length > 0 && (
            <div className="mt-4 w-full">
              <div className="grid grid-cols-2 sm:flex sm:flex-wrap gap-2">
                {userData.rooms.map((room) => (
                  <Tooltip key={room.id} content={room.name}>
                    <Card
                      key={room.id}
                      isPressable
                      className="bg-transparent w-full sm:w-[110px] cursor-pointer touch-action-none"
                      onPress={() => navigate(`/room/${room.id}`)}
                      style={{ height: '80px' }}
                    >
                      <CardBody className="p-1 bg-transparent">
                        <div className="w-full h-full flex items-center justify-center" style={{ maxWidth: '30px', maxHeight: '30px', margin: 'auto' }}>
                          <Avatar 
                            src={getAvatarUrl({
                              avatar: room.avatar,
                              id: room.id.toString(),
                              set: 'set4'
                            })}
                            className="w-full h-full"
                            isBordered
                          />
                        </div>
                      </CardBody>
                      <CardFooter className="p-1 bg-transparent">
                        <p className="text-[10px] line-clamp-2 w-full text-center">
                          {room.name}
                        </p>
                      </CardFooter>
                    </Card>
                  </Tooltip>
                ))}
              </div>
            </div>
          )}
        </CardHeader>
        <Divider />
        <CardBody className="p-6">
          <h2 className="text-xl font-semibold mb-4">{t('userProfile.sections.videos')}</h2>
          {videos.length > 0 ? (
            <VideoList videos={videos} onVideoClick={handleVideoClick} />
          ) : (
            <p className="text-center text-gray-500">{t('userProfile.messages.noVideos')}</p>
          )}
        </CardBody>
      </Card>
    </motion.div>
  );
};

export default UserProfile;
