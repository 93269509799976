const generateAvatarUrl = (id: string, size: number = 300, set: string = 'set5') => {
  if (!id) return '';
  return `https://robohash.org/${id}?size=${size}x${size}&set=${set}`;
};

export const getAvatarUrl = (avatarInfo: { 
    avatar?: string; 
    id: string, 
    set?: string
}) => {
    if (avatarInfo.avatar?.startsWith('data:image')) {
        return avatarInfo.avatar;
    }
    return generateAvatarUrl(avatarInfo.id, 300, avatarInfo.set);
}; 